<template>
	<div class="queryIdDetail">
		<!-- <div class="ribao_top">
      <div class="ribao_top_shi" @click="jianshi">前一天</div>
      <div class="ribao_top_xuashi" @click="shijian">
        <van-icon name="clock-o" size="14" />
        <span>{{ currentDate_top }}</span>
      </div>
      <div class="ribao_top_shi" @click="jiashi">后一天</div>
    </div> -->
		<van-dialog v-model="showConfirm" show-cancel-button>
			日报提交成功，请去消息窗口查看推送的日报
		</van-dialog>
		<van-dialog v-model="showModel1" title="" confirmButtonColor="#3a68f2">
			递交成功
		</van-dialog>
		<van-popup v-model="showshi" @click-overlay="guanshaa" position="bottom" :style="{ height: '50%' }">
			<van-datetime-picker v-model="currentDate" type="date" title="选择年月日" @confirm="queshi" @cancel="shixiao"
				@change="bianshi" />
		</van-popup>
		<div class="ribaoxiangq">
			<!-- 今日计划进展 -->
			<div class="ribaoxiangqsa">
				<div class="ribaoxiang" @click="rixiao(0)">
					<div>
						<img src="./../assets/icon/jihua.png" alt="" />
						<span>今日计划进展</span>
					</div>
					<van-icon name="arrow-down" v-if="rixiaoxians[0]" size="12" />
					<van-icon name="arrow" v-else size="12" />
				</div>
				<div class="ribaoqing" v-show="rixiaoxians[0]">
					<div v-for="item in logDetails.logDetails" :key="item.detailId" v-show="item.type == 1">
						<div class="ribaoqing_top">
							<van-icon name="arrow" size="10" />
						</div>
						<div class="ribaoqing_nei">
							<div class="neirong" @click="turnPath(item, $event)">{{ item.taskName }}</div>
							<div class="ribaoqiea" v-if="item.isDo=='0'">
								<van-icon name="bag-o" size="12" />
								<span>耗时：</span>
								{{item.useTime}}
								<!-- <van-stepper max="1440" v-model="item.useTime" /> -->
								<!-- <input
                  v-model="item.useTime"
                   type="number"
                  @input="inputChang(item, index)"
                  class="ribaoqing_nei_inp"
                /> -->
								<span>分钟</span>
							</div>
							<div class="ribaoqiea" v-else>
								<van-icon name="bag-o" size="12" />
								<span>耗时：</span>
								0
								<span>分钟</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- 今日临时工作 -->
			<div class="ribaoxiangqsa">
				<div class="ribaoxiang" @click="rixiao(1)">
					<div>
						<img src="./../assets/icon/ling.png" alt="" />
						<span>今日临时工作</span>
					</div>
					<van-icon name="arrow-down" v-if="rixiaoxians[1]" size="12" />
					<van-icon name="arrow" v-else size="12" />
				</div>
				<div class="ribaoqing2" v-show="rixiaoxians[1]">
					<div v-for="(item, index) in logDetails.logDetails" :key="item.detailId" v-show="item.type == 2">
						<div class="ribaoqing_nei">
							<div class="neirong" @click="turnPath(item, $event)">{{ item.taskName }}</div>
							<div class="ribaoqiea" v-if="item.isDo=='0'">
								<van-icon name="bag-o" size="12" />
								<span>耗时：</span>
								{{item.useTime}}
								<!-- <van-stepper max="1440"   v-model="item.useTime" /> -->
								<!-- <input
                  v-model="item.useTime"
                  type="number"
                  class="ribaoqing_nei_inp"
                  @input="inputChang(item, index)"
                /> -->
								<span>分钟</span>
								<!-- <div class="biaowei" @click="logSubmit(item, index)">
                  标为反馈任务
                </div> -->
							</div>
							<div class="ribaoqiea" v-else>
								<van-icon name="bag-o" size="12" />
								<span>耗时：</span>
								0
								<span>分钟</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!--明日工作计划  -->
			<div class="ribaoxiangqsa">
				<div class="ribaoxiang" @click="rixiao(2)">
					<div>
						<img src="./../assets/icon/jihua.png" alt="" />
						<span>明日工作计划</span>
					</div>
					<van-icon name="arrow-down" v-if="rixiaoxians[2]" size="12" />
					<van-icon name="arrow" v-else size="12" />
				</div>
				<div class="ribaoqingminggri" v-show="rixiaoxians[2]">
					<div @click="turnPath(item, $event)" v-for="(item, index) in TomorrowWork" :key="item.detailId"
						v-show="item.type == 3">
						<span>{{ index + 1 }} .{{ item.taskName }} </span>
						<!-- <van-icon name="arrow" size="12" /> -->
					</div>
				</div>
			</div>
			<!-- 今日反馈任务 -->
			<div class="ribaoxiangqsa">
				<div class="ribaoxiang" @click="rixiao(3)">
					<div>
						<van-icon name="completed" size="15" color="#5287F0" />
						<span>今日反馈任务</span>
					</div>
					<van-icon name="arrow-down" v-if="rixiaoxians[3]" size="12" />
					<van-icon name="arrow" v-else size="12" />
				</div>
				<div class="ribaoqing3" v-show="rixiaoxians[3]">
					<div v-for="(item, index) in TomorrowFeedback" :key="item.detailId" v-show="item.type == 4">
						<div class="ribaoqing3_neia" @click="turnPath(item, $event)">
							{{ index + 1 }} .{{ item.taskName }}
						</div>
						<!-- <div class="chehui" @click="chehui(item, index)">撤回</div> -->
					</div>
				</div>
			</div>
			<!-- 备注 -->
			<div class="ribaoxiangqsa">
				<div class="ribaoxiang">
					<div>
						<img src="./../assets/icon/bei.png" alt="" />
						<span>备注</span>
					</div>
				</div>
				<div class="ribaoqing4_neia">
					<textarea disabled v-model="remark"></textarea>
				</div>
			</div>
		</div>

		<!-- <div class="submitRibao" v-if="zhengque">
      <div class="submit" v-if="!tijoa" @click="dailySubmit">提交</div>
      <div class="submit8dian" v-else>次日8点后不允许提交</div>
    </div> -->
		<!-- <div class="submitRi"></div> -->
	</div>
</template>

<script>
	import topa from "./../components/HelloWorld.vue";
	import {
		log_getLog,
		getToken1,
		log_getLogQuality,
		system_user,
		log_submit,
		getToken,
		LogInsert,
		log_submitLog,
	} from "./../utils/api";
	import {
		Toast
	} from "vant";
	export default {
		props: {},
		data() {
			return {
				showshi: false,
				currentDate: "",
				showConfirm: false,
				currentDate_top: "",
				showModel1: false,
				activeribao: 0,
				ribaoinput: "67",
				route_query: {},
				log_getLogQuality_detaile: {}, //获取到的日报质量
				a: 1,
				remark: "",
				zhengque: false,
				tijoa: false,
				logDetails: {},
				logDate: "",
				nextDate: "",
				TomorrowWork: [], //明日工作计划
				TomorrowFeedback: [], //今日反馈计划
				rixiaoxian: [{}, {}, {}, {}, {}], //控制下面的内容小时影藏
				rixiaoxians: [true, true, true, true, true], //控制下面的内容小时影藏
			};
		},
		created() {
			// this.getlog_getLog(this.$route.query.id);
			// return
			if (localStorage.getItem('token')) {
				this.getlog_getLog(this.$route.query.id);
			} else {
				if (!this.$route.query.code) {
					let id = this.$route.query.id
					//   console.log(this.$route.query)
					// https://passport.feishu.cn/suite/passport/oauth/authorize?client_id=cli_a3799b63a06b500b&redirect_uri=http%3A%2F%2Ffeishuh5.ilohhas.com&response_type=code&scope=snsapi_userinfo&state=isFsCode
					location.replace(
						"https://open.feishu.cn/open-apis/authen/v1/index?app_id=cli_a5c0b66cd37fd00b&redirect_uri=http%3A%2F%2Ffeishuh5.ilohhas.com%2FribaoTYpe%3Fid%3D" +
						id + "&state=a123");
				} else {
					//   // console.log(this.$route.query)
					this.login(this.$route.query.code, this.$route.query.id);
				}
			}
		},
		watch: {
			$route: {
				handler: function(val, oldVal) {
					console.log(val);
				},
				// 深度观察监听
				deep: true
			}
		},
		mounted() {
			// this.route_query.id = this.$route.query.id;
			// this.route_query.id = this.$route.query.id;
			// this.logDate = this.$route.query.logDate;
			// this.nextDate = this.$route.query.nextDate;
			// console.log(this.route_query);
			// if (this.route_query) {
			//   this.currentDate_top = this.timeFunc(
			//     new Date(this.route_query.reportDate).getTime(),
			//     "date"
			//   );
			// }
			// this.getlog_getLog1(this.logDate,this.nextDate);
			// this.getlog_getLog(this.$route.query.id);
			// this.getlog_getLog(47);
			// this.getlog_getLogQuality(
			//   this.route_query.userId,
			//   this.route_query.reportDate
			// );
		},
		methods: {
			async login(code, id) {
				let that = this;
				getToken(code).then((res) => {
					if (res.code == 200) {
						localStorage.setItem("token", res.token);
						this.getlog_getLog(id);
					} else {
						let id = this.$route.query.id
						console.log(this.$route.query)
						// https://passport.feishu.cn/suite/passport/oauth/authorize?client_id=cli_a3799b63a06b500b&redirect_uri=http%3A%2F%2Ffeishuh5.ilohhas.com&response_type=code&scope=snsapi_userinfo&state=isFsCode
						location.href =
							"https://passport.feishu.cn/suite/passport/oauth/authorize?client_id=cli_a3799b63a06b500b&redirect_uri=http%3A%2F%2Ffeishuh5.ilohhas.com%2FribaoTYpe%3Fid%3D" +
							id + "&response_type=code&scope=snsapi_userinfo&state=a123";
					}
				});
			},
			getlog_getLog1(logDate, nextDate) {
				let that = this
				let TomorrowWork = []; //明日工作计划
				let TomorrowFeedback = []; //今日反馈计划
				LogInsert({
					logDate: logDate,
					nextDate: nextDate,
				}).then((res) => {
					// eslint-disable-next-line no-empty
					if (res.code == 200) {
						this.zhengque = true;
						let createTime = new Date(
							this.timeFunc(new Date().getTime() + 24 * 60 * 60 * 1000, "date") +
							" 08:00"
						).getTime();
						let nextteTime = new Date().getTime();
						if (createTime >= nextteTime) {
							this.tijoa = false;
						}
						console.log(createTime, "createTimecreateTimecreateTime");
						this.currentDate = new Date(res.data.reportDate);
						this.currentDate_top = res.data.reportDate;
						if (res.data.logDetails.length == 0) {
							Toast('暂无数据')
						}
						res.data.logDetails.map((re) => {
							// if(!re.useTime){
							//   re.useTime = 1
							// }
							if (re.type == 3) {
								TomorrowWork.push(re);
							} else if (re.type == 4) {
								TomorrowFeedback.push(re);
							}
							this.TomorrowWork = TomorrowWork;
							this.TomorrowFeedback = TomorrowFeedback;
							this.logDetails = res.data;
							this.route_query.userId = res.data.userId;
							this.remark = res.data.remark;
						})
					}

				});
			},
			// 获取日报详情
			getlog_getLog(e) {
				let that = this
				let TomorrowWork = []; //明日工作计划
				let TomorrowFeedback = []; //今日反馈计划
				log_getLog(e).then((res) => {
					that.zhengque = true;
					let createTime = new Date(
						this.timeFunc(new Date().getTime() + 24 * 60 * 60 * 1000, "date") +
						" 08:00"
					).getTime();
					let nextteTime = new Date().getTime();
					if (createTime >= nextteTime) {
						that.tijoa = false;
					}
					console.log(createTime, "createTimecreateTimecreateTime");
					if (!res.data) {
						Toast('暂无数据')
						return
					}
					that.currentDate = new Date(res.data.reportDate);
					that.currentDate_top = res.data.reportDate;
					if (res.data.logDetails.length == 0) {
						Toast('暂无数据')
					}
					res.data.logDetails.map((re) => {
						// if(!re.useTime){
						//   re.useTime = 1
						// }
						if (re.type == 3) {
							TomorrowWork.push(re);
						} else if (re.type == 4) {
							TomorrowFeedback.push(re);
						}
					});
					that.TomorrowWork = TomorrowWork;
					that.TomorrowFeedback = TomorrowFeedback;
					that.logDetails = res.data;
					that.route_query.userId = res.data.userId;
					that.remark = res.data.remark;
					// system_user(res.data.userId).then((res) => {
					//   this.route_query.userName = res.data.userName;
					// });
				});
			},
			// 获取第二天的时间处理函数
			timeFunc(content, type) {
				const date = new Date(content);
				const y = date.getFullYear();
				let m = date.getMonth() + 1;
				m = m < 10 ? "0" + m : m;
				let d = date.getDate();
				d = d < 10 ? "0" + d : d;
				let h = date.getHours();
				h = h < 10 ? "0" + h : h;
				const minute = date.getMinutes();
				const second = date.getSeconds();
				if (type === "date") {
					return y + "-" + m + "-" + d;
				}
				if (type === "time") {
					return y + "-" + m + "-" + d + " " + h + ":" + minute + ":" + second;
				}
			},
			// 获取日报质量
			getlog_getLogQuality(e, el) {
				log_getLogQuality(e, el).then((res) => {
					console.log(res, '33232');
					res.data.rate = res.data.rate * 100;
					res.data.twoWeekProportion = res.data.twoWeekProportion * 100;
					this.log_getLogQuality_detaile = res.data;
				});
			},

			// 跳转到详情页
			turnPath(val, e) {
				return
				console.log("点击", val);
				console.log(e, "eeeeeee");
				var el1 = e.currentTarget;
				var el2 = e.target._prevClass;
				console.log(el1, "el1el1el1el1");
				console.log(el2, "el2el2el2el2");
				console.log(el1 == el2);
				if (el2 == "ribaoqing_nei_inp") return;
				if (val) {
					let a = {
						userName: this.route_query.userName,
						taskId: val.taskId,
					};
					this.$router.push({
						name: "TaskDescription",
						query: {
							type: JSON.stringify(a)
						},
					});
				}
			},

			// 点击弹出时间选择器;
			shijian() {
				this.showshi = true;
				this.currentDate_top = this.timeFunc(
					new Date(this.currentDate_top).getTime(),
					"date"
				);
				this.currentDate = new Date(this.currentDate_top);
			},
			// 当点击时间确认按钮时的事件
			queshi(e) {
				let shijian = this.timeFunc(new Date(e).getTime(), "date");
				this.currentDate_top = shijian;
				console.log(this.currentDate_top, "adsasdf");
				if (this.activeribao == 0) {
					// this.getlog_getLog(this.route_query.id);
					this.getlog_getLog1(this.logDate, this.nextDate);
				} else {
					this.getlog_getLogQuality(
						this.route_query.userId,
						this.currentDate_top
					);
				}
				this.showshi = false;
			},
			// 当时间发生改变时
			bianshi() {
				let shijian = this.timeFunc(
					new Date(this.currentDate_top).getTime(),
					"date"
				);
				console.log(shijian, "只改表");
				this.currentDate_top = shijian;
			},
			// 点击时间取消按钮时
			shixiao() {
				this.currentDate_top = this.timeFunc(
					new Date(this.currentDate_top).getTime(),
					"date"
				);
				this.showshi = false;
			},
			// 关闭弹出框事件
			guanshaa() {
				this.currentDate_top = this.timeFunc(
					new Date(this.currentDate_top).getTime(),
					"date"
				);
			},
			// 点击增加一天时
			jiashi() {
				console.log(this.currentDate_top);
				let shi = new Date(this.currentDate_top).getTime() + 86400000;
				console.log(shi);
				let shijian = this.timeFunc(shi, "date");
				console.log(shijian);
				this.currentDate_top = shijian;
				if (this.activeribao == 0) {
					// this.getlog_getLog(this.route_query.id);
					this.getlog_getLog1(this.logDate, this.nextDate);
				} else {
					this.getlog_getLogQuality(
						this.route_query.userId,
						this.currentDate_top
					);
				}
			},
			// 点击减少一天
			jianshi() {
				console.log(this.currentDate_top);
				let shi = new Date(this.currentDate_top).getTime() - 86400000;
				console.log(shi);
				let shijian = this.timeFunc(shi, "date");
				console.log(shijian);
				this.currentDate_top = shijian;
				if (this.activeribao == 0) {
					// this.getlog_getLog(this.route_query.id);
					this.getlog_getLog1(this.logDate, this.nextDate);
				} else {
					this.getlog_getLogQuality(
						this.route_query.userId,
						this.currentDate_top
					);
				}
			},
			// 时间处理函数
			timeFunc(content, type) {
				const date = new Date(content);
				const y = date.getFullYear();
				let m = date.getMonth() + 1;
				m = m < 10 ? "0" + m : m;
				let d = date.getDate();
				d = d < 10 ? "0" + d : d;
				let h = date.getHours();
				h = h < 10 ? "0" + h : h;
				const minute = date.getMinutes();
				const second = date.getSeconds();
				if (type === "date") {
					return y + "-" + m + "-" + d;
				}
				if (type === "time") {
					return y + "-" + m + "-" + d + " " + h + ":" + minute + ":" + second;
				}
			},
			// input输入框的事件
			inputChang(e, a) {
				console.log(e, a);
				if (e.useTime < 1) {
					let c = e;
					c.useTime = 1;
					this.$set(this.logDetails.logDetails, a, c);
				}
			},
			// 点击下面的显示隐藏
			rixiao(e) {
				let arr = this.rixiaoxians[e];
				arr = !arr;
				this.rixiaoxians.splice(e, 1, arr);
			},
			tabs_zctive(e) {
				console.log(e);
				if (e == 1) {
					this.getlog_getLogQuality(
						this.route_query.userId,
						this.currentDate_top
					);
				} else {
					// this.getlog_getLog(this.route_query.id);
				}
			},
			logSubmit(e, index) {
				let a = JSON.parse(JSON.stringify(e));
				a.type = "4";
				this.TomorrowFeedback.push(a);
				console.log(this.TomorrowFeedback);
				this.logDetails.logDetails.splice(index, 1);
			},
			chehui(e, index) {
				// e.type = "2";
				let a = JSON.parse(JSON.stringify(e));
				a.type = "2";
				// debugger
				this.logDetails.logDetails.push(a);
				this.TomorrowFeedback.splice(index, 1);
			},
			//日报提交
			dailySubmit() {
				// debugger
				console.log(this.logDetails.logDetails, "this.logDetailsthis.logDetails");
				let dailySubm = this.logDetails;
				dailySubm.logDetails = [
					...this.logDetails.logDetails,
					...this.TomorrowFeedback,
				];
				console.log(dailySubm, "dailySubmdailySubmdailySubm");
				let useTime = 0;
				dailySubm.logDetails.map((res) => {
					useTime = useTime + Number(res.useTime);
				});
				console.log(useTime, "useTimeuseTime");
				if (useTime > 480) {
					Toast("日报时间总和不得大于480min");
					return;
				}
				log_submit(dailySubm).then((res) => {
					console.log(res);
					if (res.code == 200) {
						// Toast("提交成功");
						// this.getlog_getLog(this.route_query.id);
						this.showConfirm = true;
						//   this.$router.push("/Index");
					} else {
						Toast("系统繁忙，请稍后再试");
					}
				});
			},
		},

		components: {
			topa,
		},
	};
</script>

<style scoped lang="less">
	.queryIdDetail {
		/deep/.van-dialog__content {
			padding: 20px;
		}
	}

	.ribao_top {
		width: 100%;
		height: 40px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-bottom: 1px solid rgba(224, 227, 234, 100);
		box-sizing: border-box;
	}

	.ribao_top_xuashi {
		width: 59%;
		height: 100%;
		border-right: 1px solid rgba(224, 227, 234, 100);
		border-left: 1px solid rgba(224, 227, 234, 100);
		box-sizing: border-box;
		color: rgba(184, 188, 197, 100);
		font-size: 14px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.ribao_top_shi {
		width: 20%;
		font-size: 14px;
		box-sizing: border-box;
	}

	.ribaoa {
		width: 95%;
		margin: 0 auto;
		margin-top: 21px;
	}

	.ribaoxiangq {
		width: 95%;
		box-sizing: border-box;
		font-size: 14px;
		margin: 0 auto;
		margin-top: 30px;
		padding-bottom: 20px;
	}

	.ribaoxiangqsa {
		margin-bottom: 10px;
		border-bottom: 1px solid #ebebeb;
	}

	.ribaoxiang {
		width: 100%;
		height: 30px;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.ribaoxiang img {
		width: 14px;
	}

	.ribaoxiang div {
		height: 100%;
		display: flex;
		align-items: center;
	}

	.ribaoxiang div span {
		display: inline-block;
		margin-left: 15px;
		font-weight: bold;
	}

	.ribaoqing {
		width: 100%;
		background: #f7f9fa;
		padding: 10px 7px;
		box-sizing: border-box;
		margin: 20px 0;
	}

	.ribaoqing>div {
		width: 100%;
		position: relative;
		padding-bottom: 10px;
		border-bottom: 1px solid#d4d3d3;
	}

	.ribaoqing>div:last-child {
		border: 0;
	}

	.ribaoqing_nei {
		width: 100%;
		display: flex;
		flex-direction: column;
		// padding: 0 20px;
		box-sizing: border-box;
		// justify-content: space-between;
		align-items: baseline;
	}

	/deep/.van-stepper {
		display: inline-block;
	}

	.neirong {
		width: 100%;
		text-align: left;
		padding-top: 10px;
		// overflow: hidden;
		// text-overflow: ellipsis;
		// white-space: nowrap;
		margin-bottom: 20px;
	}

	.ribaoqiea {
		width: 100%;
		display: flex;
		align-items: center;
		/* display: flex;
  align-items: center;
  margin-bottom: 10px; */
		text-align: left;
	}

	.ribaoqiea span {
		display: inline-block;
		margin-left: 8px;
	}

	.ribaoqing_neis {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		box-sizing: border-box;
		justify-content: space-evenly;
		text-align: left;
	}

	.ribaoqing_neis p {
		margin: 0;
		padding: 0;
	}

	.ribaoqing_top {
		text-align: right;
		position: absolute;
		right: 10px;
		top: 10px;
	}

	.ribaoqing_nei_inp {
		width: 60px;
		height: 25px;
		box-sizing: border-box;
		border: 1px solid #c3c3c3;
		text-align: center;
		line-height: 25px;
	}

	.ribaoqing2 {
		width: 100%;
		background: #f7f9fa;
		padding: 10px 12px;
		box-sizing: border-box;
	}

	.ribaoqing2>div {
		display: flex;
		padding: 4px 0;
		flex-direction: column;
		align-items: flex-end;
		border-bottom: 1px dashed rgba(187, 187, 187, 100);
	}

	.ribaoqingminggri {
		width: 100%;
		background: #f7f9fa;
		padding: 10px 12px;
		box-sizing: border-box;
	}

	.ribaoqingminggri>div {
		display: flex;
		padding: 4px 0;
		justify-content: space-between;
		align-items: center;
		border-bottom: 1px dashed rgba(187, 187, 187, 100);
		text-align: left;
	}

	.biaowei {
		padding: 4px 12px;
		font-size: 12px;
		float: right;
		margin-left: 30px;
		color: #fff;
		background: rgb(64, 64, 216);
		border-radius: 5px;
	}

	.ribaoqing3 {
		width: 100%;
		background: #f7f9fa;
		padding: 10px 12px;
		box-sizing: border-box;
	}

	.ribaoqing3>div {
		width: 100%;
		display: flex;
		justify-content: space-between;
	}

	.ribaoqing3_neia {
		flex: 1;
		text-align: left;
	}

	.chehui {
		padding: 7px 4px;
		color: rgb(64, 64, 216);
	}

	.ribaoqing4_neia {
		width: 100%;
		background: #f7f9fa;
		padding: 10px;
		box-sizing: border-box;
	}

	.ribaoqing4_neia textarea {
		width: 100%;
		height: 80px;
		text-align: left;
		border: none;
	}

	.ribaoqing4 {
		width: 100%;
		padding: 10px 12px;
		padding-bottom: 0;
		padding-left: 25px;
		box-sizing: border-box;
	}

	.ribaoqing4 div {
		width: 100%;
		text-align: left;
		margin-bottom: 15px;
		font-size: 14px;
		color: #000;
	}

	.ribaoqing4 div:last-child {
		margin-bottom: 0;
	}

	.ribaoqing5 {
		width: 100%;
		background: #f7f9fa;
		padding: 10px 12px;
		box-sizing: border-box;
		margin-top: 8px;
	}

	.ribaoqing5 div {
		text-align: left;
		width: 100%;
		height: 40px;
		color: #000;
		line-height: 40px;
		border-bottom: 1px solid #d4d3d3;
	}

	.ribaoqing5 div:last-child {
		border-bottom: none;
	}
</style>
<style>
	.ribaoa .van-tabs__line {
		width: 0;
	}

	.ribaoa .van-tab--active {
		background: #427bee 1%;
	}

	.ribaoa .van-tab {
		border-radius: 10px;
		font-size: 16px;
	}

	.ribaoa .van-tabs--line .van-tabs__wrap {
		width: 80%;
		border: 1px solid #427bee;
		border-radius: 14px;
		margin: 0 auto;
	}

	.font_color {
		color: red;
	}

	.submitRi {
		width: 100%;
		height: 60px;
	}

	.submitRibao {
		position: fixed;
		bottom: 0;
		width: 100%;
		height: 60px;
		background: #ffffff;
		z-index: 3;
		display: flex;
		justify-content: center;
		align-items: center;
		border: none;
	}

	.submit {
		width: 80%;
		height: 40px;
		background: #427bee;
		border-radius: 7px;
		text-align: center;
		font-size: 18px;
		line-height: 40px;
		color: #ffffff;
	}

	.submit8dian {
		height: 40px;
		font-size: 16px;
		/* background: #427bee; */
		width: 80%;
		color: #f00;
		line-height: 40px;
	}
</style>
