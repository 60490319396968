<template>
  <div class="select">
    <van-overlay :show="showloading" @click="showloading = false">
      <div class="wrapper" @click.stop>
        <van-loading
          v-if="showloading == true"
          class="loading"
          color="#1989fa"
          vertical
          >加载中...</van-loading
        >
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { submitLog,LogInsert1,log_getNextDay,getLogByLogDate, LogInsert, getToken,getToken1,asana_login,asana_login1,getCompanyInfo,getInfo } from "./../utils/api";
import { Dialog, Toast } from "vant";
export default {
  data(){
    return{
      currentDate_top:"",
      currentDate_top1:"",
      showloading:false
    }
  },
  methods:{
    timeFunc(content, type) {
      const date = new Date(content);
      const y = date.getFullYear();
      let m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      let h = date.getHours();
      h = h < 10 ? "0" + h : h;
      const minute = date.getMinutes();
      const second = date.getSeconds();
      if (type === "date") {
        return y + "-" + m + "-" + d;
      }
      if (type === "time") {
        return y + "-" + m + "-" + d + " " + h + ":" + minute + ":" + second;
      }
    },
  },
  mounted(){
      if(localStorage.getItem('name')){
        this.name = localStorage.getItem('name')
      }
      localStorage.removeItem('logDetails')
      localStorage.removeItem('TomorrowFeedback')
      let day3 = null;
      let time1 = new Date().getTime()
      let time2 = new Date((this.timeFunc(new Date().getTime(), "date") +" 08:00").replace(/-/g, '/')).getTime()
      if(time1>=time2){
        this.currentDate_top = this.timeFunc(new Date().getTime(), "date");
      }else{
        this.currentDate_top = this.timeFunc((new Date().getTime()-24 * 60 * 60 * 1000), "date");
      }
      // this.getNextDay()
      //console.log(localStorage.getItem('token'),'feishu03')
      log_getNextDay(this.currentDate_top).then(res=>{
        console.log(res,'feishu02')

        if(res.code==200){
          this.currentDate_top1 = res.data.nextDate
          let that = this
          this.showloading = true
          LogInsert1({
            logDate:  that.currentDate_top,
            nextDate: that.currentDate_top1,
          }).then(res=>{
            this.showloading = false
            if(res.code == 200){
              this.$router.push({
                path:"empty3",
              })
            }else{
                res.msg = res.msg.replace(/;/g, '\r\n')
                Dialog.alert({
                  className:"btn1111",
                  message: "请先去完成任务或调整计划\r\n\r\n<span class='btn2222'>"+res.msg+"</span>",
                  title:"有未完成的任务",
                  confirmButtonColor:"#3a68f2"
                }).then(() => {
                });
            }
          })
        }else{
          Dialog.alert({
            message: res.msg+"token:"+localStorage.getItem('token'),
            confirmButtonColor:"#3a68f2"
          }).then(() => {
          });
        }
      })
      // if (new Date().getDay() == 5) {
      //   day3 = new Date();
      //   day3.setTime(day3.getTime() + 3 * 24 * 60 * 60 * 1000);
      //   this.currentDate_top1 =
      //     day3.getFullYear() +
      //     "-" +
      //     (day3.getMonth() + 1 < 10
      //       ? "0" + (day3.getMonth() + 1)
      //       : day3.getMonth() + 1) +
      //     "-" +
      //     (day3.getDate()< 10  ? "0" + day3.getDate() 
      //       : day3.getDate());
      // } else if (new Date().getDay() == 6) {
      //   day3 = new Date();
      //   day3.setTime(day3.getTime() + 2 * 24 * 60 * 60 * 1000);
      //   this.currentDate_top1 =
      //     day3.getFullYear() +
      //     "-" +
      //     (day3.getMonth() + 1 < 10
      //       ? "0" + (day3.getMonth() + 1)
      //       : day3.getMonth() + 1) +
      //     "-" +
      //     (day3.getDate()< 10  ? "0" + day3.getDate() 
      //       : day3.getDate());
      // } else {
      //   day3 = new Date();
      //   day3.setTime(day3.getTime() + 24 * 60 * 60 * 1000);
      //   this.currentDate_top1 =
      //     day3.getFullYear() +
      //     "-" +
      //     (day3.getMonth() + 1 < 10
      //       ? "0" + (day3.getMonth() + 1)
      //       : day3.getMonth() + 1) +
      //     "-" +
      //     (day3.getDate()< 10  ? "0" + day3.getDate() 
      //       : day3.getDate());
      // }
      // this.currentDate_top = this.$route.query.currentDate_top
      // this.currentDate_top1 = this.$route.query.currentDate_top1
     
  },
}
</script>

<style scope lang="less">
.btn1111{
    .van-dialog__header{
      font-weight: bold;
    }
    .van-dialog__message{
      text-align: left;
    }
    .btn2222{
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 5;
      overflow: hidden;
    }
  }
.select {
 .van-overlay{
    background-color: transparent;
  }
  .loading {
    position: fixed;
    top: 40%;
    left: 45%;
  }
}
</style>