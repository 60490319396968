<template>
  <div class="richeng">
    <van-dialog
      :before-close="beforeClose1"
       confirmButtonColor="#3a68f2"
      v-model="showConfirm"
    >
     乐活日历是打通飞书日历和的幕后“英雄”,安装即可正常使用，不需要打开本日历应用的哦~
      <!-- 当天耗时未满420分钟，请确认是否提交？ -->
    </van-dialog>
  </div>
</template>

<script>
export default {
    data(){
        return{
            showConfirm:true
        }
    }
}
</script>

<style scoped lang="less">
.richeng {
  /deep/.van-dialog__content {
    padding: 20px;
  }
}
</style>